import FormValidation from '@/js/components/forms/formValidation';

class Contact {
  constructor() {
    this.dom = {
      formContact: document.getElementById('form-contact'),
    };

    this.components = {
      contactFormValidation: null,
    };

    this.mount();
  }

  mount() {
    if (this.dom.formContact) {
      this.components.contactFormValidation = new FormValidation(this.dom.formContact);
    }
  }
}

if (document.getElementById('page-widgets')) {
  // eslint-disable-next-line no-new
  new Contact();
}
