export default class LinkConcealer {
  /**
   * @param element {HTMLButtonElement}
   */
  constructor({ link }) {
    this.dom = {
      link,
    };

    this.events = {
      onLinkClick: this.onLinkClick.bind(this),
    };

    this.mount();
  }

  mount() {
    if (this.dom.link) {
      this.dom.link.addEventListener('click', this.events.onLinkClick);
    }
  }

  onLinkClick(e) {
    const el = e.currentTarget;
    el.classList.remove('hide');
    el.removeEventListener('click', this.events.onLinkClick);
  }
}
